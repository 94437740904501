import "./App.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import HomePage from "./Views/HomePage";
import MasagePage from "./Views/MasagePage";
import MainLayoutContent from "./Layouts/MainLayout";
import DrinksPage from "./Views/DrinksPage";

const MainLayout = () => (
  <MainLayoutContent>
    <Outlet />
  </MainLayoutContent>
);

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/table/:tableId" element={<HomePage />} />
            <Route path="/MasagePage" element={<MasagePage />} />
            <Route path="/DrinksPage" element={<DrinksPage />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
