import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../logo.svg";

const MainLayout = ({ children }: any) => {
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsHeaderActive(
      !(location.pathname === "/") && !location.pathname.includes("/table/")
    );
  }, [location]);

  return (
    <div className="App-main-container">
      <header className={`App-header ${isHeaderActive && "active"}`}>
        <Link to="/">
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
      </header>
      <>{children}</>
    </div>
  );
};

export default MainLayout;
