import { IMenuListProps } from "@/types";
import style from "./MenuList.module.scss";

const MenuList = ({ categories }: IMenuListProps) => {
  return (
    <div
      className={`grid grid-cols-1 gap-4 lg:gap-8 lg:grid-cols-4 mt-20 p-4 ${style.pageWrapper}`}
    >
      {categories.map((category) => (
        <div key={category.name} className={style.categoryContent}>
          <h2>{category.name}</h2>
          <div className={style.categoryItemsContainer}>
            {category.items.map((item) => (
              <div className={style.categoryItem}>
                <div>{item.name}</div>
                <div className={style.itemPrice}>{item.price.toFixed(2)} €</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuList;
